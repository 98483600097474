import { css } from "@emotion/react"
import moment from "moment"
import React, { CSSProperties } from "react"
import { Announcement } from "../../../types"
const LinesEllipsis = require("react-lines-ellipsis")
const styles = require("./style.module.scss")

interface AnnouncementItemProps {
  style?: CSSProperties
  announcement: Announcement
  onClick?(): void
  isSelected: boolean
  index: number
  length: number
}

const container = css`
  width: 100%;
  padding: 8px 0;

  cursor: pointer;
`

const hBoxSpaced = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const titleText = css`
  font-family: "Noto Sans KR";
  font-size: 15px;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`

// TODO: use colours from global colours file

const dateText = css`
  font-size: 12px;
  font-weight: 500;
  color: #8e8e8e;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`

const AnnouncementItem = ({
  style,
  announcement,
  index,
  onClick,
  isSelected,
  length,
}: AnnouncementItemProps) => {
  return (
    <article
      css={container}
      style={{ ...style, borderBottom: index === length ? undefined : "0.5px solid #d4d4d4" }}
      onClick={onClick}
    >
      <div css={hBoxSpaced}>
        <span css={titleText} style={{ color: isSelected ? "rgb(181, 56, 67)" : "rgb(0, 0, 0)" }}>
          {announcement.title}
        </span>
        <span
          css={dateText}
          style={{ color: isSelected ? "rgb(181, 56, 67)" : "rgb(142, 142, 142)" }}
        >
          {moment(announcement.date).format("YYYY-MM-DD")}
        </span>
      </div>
    </article>
  )
}
export default AnnouncementItem
