import React from "react"
import { MarginStyle } from "../../../types"
const styles = require("./style.module.scss")

interface ArrowButtonProps {
  disabled?: boolean
  style?: MarginStyle
  arrowDirection: "LEFT" | "RIGHT"
  name?: string
  onClick?: () => void
  isRed?: boolean
}

const ArrowButton = ({
  disabled = false,
  style,
  arrowDirection,
  name,
  onClick,
  isRed,
}: ArrowButtonProps) => {
  return (
    <div
      data-testid="button"
      style={style}
      className={
        styles["arrowButtonContainer"] + " h-box" + (disabled ? " " + styles["disabled"] : "")
      }
      onClick={disabled ? () => null : onClick}
    >
      {arrowDirection === "LEFT" && (
        <img
          src={
            isRed
              ? require("../../../images/see-more-red.svg")
              : require("../../../images/see-more.svg")
          }
          className={styles["leftArrow"]}
        />
      )}
      {name !== undefined ? (
        <span className={styles["arrowButtonTitle"]} style={{ color: isRed ? "#B53843" : "black" }}>
          {name}
        </span>
      ) : null}

      {arrowDirection === "RIGHT" && (
        <img
          src={
            isRed
              ? require("../../../images/see-more-red.svg")
              : require("../../../images/see-more.svg")
          }
          className={styles["rightArrow"]}
        />
      )}
    </div>
  )
}

export default ArrowButton
